import React from "react";
import "./header.css";
import CTA from "./CTA";
import ME from '../../assets/me-about.png'
import HeaderSocials from "./HeaderSocials";
import {AiOutlineArrowRight} from "react-icons/ai";

const Header = () => {
  return (
    <header id="home">
      <div className="container header__container">
        <h5>Hey there! I'm</h5>
        <h1>Srishti Dadhich</h1>
        <h4 className="text-light">MSc. Social and Organisational Psychology</h4>
        <h5 className="text-light header__text">
          Goal-oriented and driven MSc grad blending psychology insights with sales expertise for strategic recruitment solutions in London. Let's harness talent together.
        </h5>
        <div className="me">
          <img src={ME} alt="me" />
        </div>
        <CTA />
        <HeaderSocials />

        <a href="#contact" className="scroll__down">
          <AiOutlineArrowRight />
        </a>
      </div>
    </header>
  );
};

export default Header;
