import React from 'react'
import './experience.css'
import NewfoldLogo from '../../assets/newfold-logo.svg'
import PrimarkLogo from '../../assets/primark-logo.svg'
import BakevibesLogo from '../../assets/bakevibes-logo.png'

const Experience = () => {
  return (
    <section id='experience'>
      <h2>Work Experience</h2>

      <div className='container experience__container'>
        <div className="experience__cards">
          <article className="experience__card">
            <div className='experience__img__container'>
              <img src={NewfoldLogo} alt="newfold-logo" />
            </div>
            <div className='experience__data__container'>
              <div className='experience__data'>
                <div className='experience__titles'>
                  <h3>Sales Associate</h3>
                  <h3 className='text-light'>Newfold Digital | Mumbai, India</h3>
                  {/* <h4 className='text-light'>Mumbai, India</h4> */}
                </div>
                <div className='experience__duration'>
                  <h3>2021 - 2022</h3>
                </div>
              </div>

              <div className='experience__description'>
                Achieved recognition as the MVP for outstanding performance in November 2021, demonstrating expertise in product awareness by providing solutions and creating customer awareness. Actively engaged in campaigns for lead generation, fostering client rapport through effective coordination, and contributed to interface issue analysis through rigorous testing, presenting insightful reports. Proficient in Salesforce, Microsoft Office, and possess strong skills in sales presentations, target-driven approaches, and analytical problem-solving for business development.
              </div>

            </div>
          </article>
          <article className="experience__card">
            <div className='experience__img__container'>
              <img src={PrimarkLogo} alt="primark-logo" />
            </div>
            <div className='experience__data__container'>
              <div className='experience__data'>
                <div className='experience__titles'>
                  <h3>Retail Assistant</h3>
                  <h3 className='text-light'>Primark | London, UK</h3>
                  {/* <h4 className='text-light'>Mumbai, India</h4> */}
                </div>
                <div className='experience__duration'>
                  <h3>2023 - Present</h3>
                </div>
              </div>

              <div className='experience__description'>
                Exemplified exceptional customer engagement and informed decision-making at Primark, showcasing adaptability across diverse roles for heightened operational efficiency. Cultivated a collaborative work environment, contributing to a unified approach to store operations and strategically leveraging comprehensive product knowledge to drive superior customer experiences and surpass sales targets.
              </div>

            </div>
          </article>
          <article className="experience__card">
            <div className='experience__img__container'>
              <img src={BakevibesLogo} alt="bakevibes-logo" />
            </div>
            <div className='experience__data__container'>
              <div className='experience__data'>
                <div className='experience__titles'>
                  <h3>Co-founder</h3>
                  <h3 className='text-light'>Bakevibes (Currently Moonbills) | Mumbai, India</h3>
                  {/* <h4 className='text-light'>Mumbai, India</h4> */}
                </div>
                <div className='experience__duration'>
                  <h3>2020 - 2020</h3>
                </div>
              </div>

              <div className='experience__description'>
                Pioneered the establishment of an online bakery, showcasing entrepreneurial prowess. Managed diverse clientele interactions, orders, and feedback, significantly boosting business interest and accountability. Spearheaded the development of promotional policies and branding strategies on social media (@moonbills), leveraging analytical skills for effective online presence.
              </div>

            </div>
          </article>
        </div>
      </div>

    </section>
  )
}

export default Experience